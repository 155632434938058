<template lang="pug">
  b-row
    b-col.mb-4.mb-xl-0(
      md='12'
      lg='12'
      xl='5')
      b-card.manager-organizations-organization-panel.card-white.card-no-gutters.shadow.h-100(no-body)
        b-card-body.d-flex.justify-content-start.align-items-center
          template(v-if='panelShow')
            .panel-picture.mr-4
              b-avatar.wc-bubble.wc-bubble-md.wc-bubble-br.mr-3.shadow(
                :src="$getDeep(panel.data, 'attributes.portrait_url')"
                variant='white'
                alt='')
                template(v-if="!$getDeep(panel.data, 'attributes.portrait_url')" #default)
                  .wc-bubble-content.wc-bubble-linked.bg-primary
                    .i-wrapper
                      i.fal.fa-fw.fa-2x.fa-building.text-white

            .panel-info.d-flex.flex-column.flex-fill
              .panel-info-name.font-weight-bold
                | {{ panel.data.attributes.name }}
              .panel-info-identity.small.text-muted.text-break(v-if="$getDeep(panel.rs, 'identity.attributes.username')")
                | @{{ $getDeep(panel.rs, 'identity.attributes.username') }}
              .panel-info-data.mt-3(v-if="$getDeep(panel.rs, 'profile.attributes.founded')")
                span.panel-info-profile.small.mr-3
                  i.fal.fa-fw.fa-pennant.text-muted
                  |  {{ $t('activerecord.attributes.organization/profile.founded') }} {{ $getDeep(panel.rs, 'profile.attributes.founded') }}

            .state.align-self-end
              b-badge.state.p-2(
                :variant="$wc.conf.states.organization.variants[panel.data.attributes.state]"
                pill)
                i.fa-fw(:class="$wc.conf.states.organization.icons[panel.data.attributes.state]")
                |  {{ $t('organization.states.' + panel.data.attributes.state) }}
          template(v-else)
            wc-loaders-heading-avatar
    b-col.mb-4.mb-xl-0(
      md='12'
      lg='12'
      xl='4')
      b-card.manager-organizations-organization-panel.card-white.card-no-gutters.shadow.h-100(no-body)
        b-card-body
          template(v-if='panelShow')
            b-list-group.justify-content-start.p-0.h-100(flush)
              b-list-group-item.border-0(
                :title="$t('activerecord.models.account.other')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-users
                span
                  | {{ $tc('account.counts', panel.data.attributes.accounts_count, { count: panel.data.attributes.accounts_count } ) }}
              b-list-group-item.border-0(
                :title="$t('activerecord.models.machine.other')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-conveyor-belt-alt
                span
                  | {{ $tc('machine.counts', panel.data.attributes.machines_count, { count: panel.data.attributes.machines_count } ) }}
          template(v-else)
            wc-loaders-list(:lines='5')
    b-col(
      md='12'
      lg='12'
      xl='3')
      b-card.manager-organizations-organization-panel.card-white.card-no-gutters.shadow.h-100(no-body)
        b-card-body
          template(v-if='panelShow')
            b-list-group.justify-content-start.p-0.h-100(flush)
              b-list-group-item.border-0(
                :title="$t('activerecord.attributes.shared.updated_at')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-calendar-check
                span
                  | {{ panel.data.attributes.updated_at }}
              b-list-group-item.border-0(
                :title="$t('activerecord.attributes.shared.created_at')")
                span.fa-stack.mr-2.text-primary
                  i.fal.fa-circle.fa-stack-2x
                  i.fal.fa-stack-1x.fa-calendar-plus
                span
                  | {{ panel.data.attributes.created_at }}
          template(v-else)
            wc-loaders-list(:lines='5')
</template>

<script>
import { Panel } from '@common/panel'
import WcLoadersHeadingAvatar from '@components/shared/loaders/WcLoadersHeadingAvatar'
import WcLoadersList from '@components/shared/loaders/WcLoadersList'

export default {
  name: 'manager-organizations-organization-panel',
  mixins: [Panel],
  components: {
    WcLoadersHeadingAvatar,
    WcLoadersList,
  },
  watch: {
    panel() {
      this.panel.rs.identity = this.panelDataIncluded(
        this.$getDeep(this.panel.data, 'relationships.identity.data.type'),
        this.$getDeep(this.panel.data, 'relationships.identity.data.id')
      )
      this.panel.rs.profile = this.panelDataIncluded(
        this.$getDeep(this.panel.data, 'relationships.profile.data.type'),
        this.$getDeep(this.panel.data, 'relationships.profile.data.id')
      )
    },
  },
  data() {
    return {
      cacheType: 'manager/organizations/getPanel',
      cachePayload: { id: this.$route.params.organization_id },
    }
  },
}
</script>
